var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-user-plus"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function () {
        return [_vm._v("Add a new user to this company.")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('router-link', {
          staticClass: "btn btn-link",
          attrs: {
            "to": {
              path: `/company/users`
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-1"
        })])];
      },
      proxy: true
    }])
  }, [[_vm._v("Create User")]], 2), _c('div', {
    staticClass: "block-content"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errorAction.createUser,
      expression: "errorAction.createUser"
    }],
    staticClass: "alert alert-danger"
  }, [_c('strong', {
    staticClass: "mb-0"
  }, [_vm._v("Something went wrong...")]), _vm._v(_vm._s(_vm.errorAction.createUser) + " ")]), _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content",
    attrs: {
      "data-cy": "new-user-form"
    }
  }, [_c('CompanyUserForm', {
    attrs: {
      "error": _vm.validationErrors
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c('div', {
    staticClass: "text-right pb-4"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit",
      "disabled": _vm.loadingAction.createUser
    },
    on: {
      "click": _vm.onCreateUser
    }
  }, [_vm.loadingAction.createUser ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Updating...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Add User")])])])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }